<template>
  <div class="container">
    <div class="mx-auto px-4 sm:px-6 md:px-8">
      <button class="mr-5 bg-blue-700 text-white border border-blue-700 font-bold py-2 px-6 rounded-lg" @click="callAuthoriseApi()">
        Retrieve Information
      </button>
    </div>
  </div>
</template>
<script>
import api from '@/api/NDIMyInfoApi'
export default {
  name: "Home",
  data: () => {
    return {
      ret: {},
    }
  },
  methods: {
    async callAuthoriseApi () {
      this.ret = await api.callAuthoriseApi()
      console.log(this.ret)
      window.open(this.ret.data);
    },
  },
};
</script>